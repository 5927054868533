<template>
  <BaseEditable
    v-if="content"
    ref="title"
    :tag="tagWithClasses"
    v-bind="{ ...$attrs, ...$props }"
    class="menu-item--title font-weight-bold flex shrink"
    v-on="$listeners"
  />
  <span v-else-if="loggedIn" class="title-placeholder" @click="addTitle"
    >Add Title</span
  >
</template>

<script>
import { mapGetters } from 'vuex'
import selectElementText from '@utils/select-element-text'
import TagClassMixin from '@mixins/tag-class'

export default {
  name: 'MenuItemTitle',
  mixins: [TagClassMixin],
  props: {
    content: {
      type: null,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
  methods: {
    async addTitle() {
      await this.$emit('update', 'New Title')
      this.$refs['title'].$el.focus()
      selectElementText(this.$refs['title'].$el)
    }, // addTitle
  },
}
</script>

<style lang="scss">
.title-placeholder {
  font-size: 0.8rem;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%);
  cursor: pointer;
}
</style>
